.newhero-wrapper {
  /* width: 1440px; */
  height: 100vh;
  margin: auto;
}
.newhero {
  max-width: 1440px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  padding-top: 190px;
  margin: auto;
  /* background: green; */
}
.newhero-header {
  font-size: 96px;
}

.new-blocks {
  display: flex;
  width: 1000px;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 100px;

  /* background: green; */
}

.new-blocks > img {
  width: 120px;
  height: 120px;
  animation: animate 20s linear infinite;
  perspective: 800px;
}
@keyframes animate {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}
