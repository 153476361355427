@import url(https://fonts.googleapis.com/css2?family=Quattrocento:wght@400;700&display=swap);
* {
  margin: 0;
  padding: 0;
  background: black;
  color: #fff;
  font-family: "Quattrocento", serif !important;
}

.App {
  /* background-color: black; */
  overflow-x: hidden;
}

.viewport {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin-top: -200px;
}

.parallax-parent {
  position: relative;
  top: 100vh;
  left: 0;
  width: 100vh;
  height: 100vw;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-perspective-origin: 50% 50% 0;
  perspective-origin: 50% 50% 0;
  -webkit-perspective: 3px;
  perspective: 3px;
}

.parallax-parent::-webkit-scrollbar {
  display: none;
}

.background-colors {
  position: relative;
  width: 100vh;
  height: 300vw;
  background: #fffc00;
  background: linear-gradient(
    0deg,
    #fffc00 0%,
    #fffc00 25%,
    #229512 50%,
    red 100%
  );
}

.parallax-child-container {
  position: absolute;
  width: 100vh;
  height: 100vw;
  text-align: center;
  overflow: hidden;
  z-index: 1;
}

.parallax-child-container:nth-of-type(1) {
  top: 0vw;
}

.parallax-child-container:nth-of-type(2) {
  top: 100vw;
}

.parallax-child-container:nth-of-type(3) {
  top: 200vw;
}

.parallax-child {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  padding: 0 30vw;
  -webkit-transform-origin: 50% 50% 0;
  transform-origin: 50% 50% 0;
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(90deg);
  transform: translateX(-50%) translateY(-50%) rotate(90deg);
}
/* .hello {
  display: inline-block;
} */
.left-image {
}
/* .right-image {
  width: 640px;
  height: 550px;
  margin-left: -260px;
  margin-bottom: 260px;
} */
.parallax-child h1 {
  font-size: 5rem;
  font-family: sans-serif;
  font-weight: 700;
  color: whitesmoke;
  transition: color 1s ease-in-out, -webkit-transform 3s ease-in-out;
  transition: transform 3s ease-in-out, color 1s ease-in-out;
  transition: transform 3s ease-in-out, color 1s ease-in-out, -webkit-transform 3s ease-in-out;
  transition: transform 3s ease-in-out, color 1s ease-in-out,
    -webkit-transform 3s ease-in-out;
  cursor: pointer;
}

.parallax-child h1:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  color: black;
}

.parallax-background {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300vw;
  height: 100vh;
  -webkit-transform: translateX(-50%) translateY(180%) translateZ(-8px)
    scale(2.5) rotate(90deg);
  transform: translateX(-50%) translateY(180%) translateZ(-8px) scale(2.5)
    rotate(90deg);
  z-index: 0;
}

.parallax-background h1 {
  height: 100vh;
  line-height: 100vh;
  font-family: sans-serif;
  font-size: 10rem;
  text-align: center;
  -webkit-text-stroke: 2px #ccc;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 960px) {
  .viewport {
    display: none;
  }
}
.ingredients-wrapper {
  margin-top: 200px;
  margin-bottom: 100px;
}
@media (min-width: 863px) {
  @media (max-height: 1000px) {
    .ingredients-wrapper {
      margin-top: 150px;
    }
  }
  @media (max-height: 900px) {
    .ingredients-wrapper {
      margin-top: 250px;
    }
  }
  @media (max-height: 800px) {
    .ingredients-wrapper {
      margin-top: 350px;
    }
  }
  @media (max-height: 700px) {
    .ingredients-wrapper {
      margin-top: 450px;
    }
  }
  @media (max-height: 600px) {
    .ingredients-wrapper {
      margin-top: 550px;
    }
  }
  @media (max-height: 500px) {
    .ingredients-wrapper {
      margin-top: 600px;
    }
  }
  @media (max-height: 400px) {
    .ingredients-wrapper {
      margin-top: 650px;
    }
  }
}

/* @media(max-) */
.cube-wrapper {
  /* width: 1000px; */
  display: -webkit-flex;
  display: flex;
  max-width: 1280px;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  /* padding-top: 300px; */
  margin: auto;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.cube {
  width: 200px;
  height: 200px;
  position: relative;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-animation: rotate 10s linear infinite;
          animation: rotate 10s linear infinite;
}
@media (max-width: 782px) {
  .cube-wrapper {
    /* margin-top: 100px; */
  }
  .cube {
    margin-bottom: 100px;
  }
}
@media (max-width: 400px) {
  .cube-wrapper {
    margin-top: 50px;
  }
  /* .cube {
    margin-bottom: 100px;
  } */
}
.cbimg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.9;
}

/* Applying styles to each face */
.box1 {
  -webkit-transform: translateZ(100px);
          transform: translateZ(100px);
}

.box2 {
  -webkit-transform: rotateY(90deg) translateX(100px);
          transform: rotateY(90deg) translateX(100px);
  -webkit-transform-origin: right;
          transform-origin: right;
}

.box3 {
  -webkit-transform: rotateY(180deg) translateZ(100px);
          transform: rotateY(180deg) translateZ(100px);
}

.box4 {
  -webkit-transform: rotateY(-90deg) translateX(-100px);
          transform: rotateY(-90deg) translateX(-100px);
  -webkit-transform-origin: left;
          transform-origin: left;
}

.box5 {
  -webkit-transform: rotateX(-90deg) translateY(-100px);
          transform: rotateX(-90deg) translateY(-100px);
  -webkit-transform-origin: top;
          transform-origin: top;
}

.box6 {
  -webkit-transform: rotateX(90deg) translateY(100px);
          transform: rotateX(90deg) translateY(100px);
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
}

/* Animating the elements */
@-webkit-keyframes rotate {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotateY(90deg) rotateZ(90deg);
            transform: rotateY(90deg) rotateZ(90deg);
  }
  40% {
    -webkit-transform: rotateY(180deg) rotateZ(-90deg);
            transform: rotateY(180deg) rotateZ(-90deg);
  }
  60% {
    -webkit-transform: rotateY(270deg) rotateZ(90deg);
            transform: rotateY(270deg) rotateZ(90deg);
  }
  80% {
    -webkit-transform: rotateY(360deg) rotateZ(-90deg);
            transform: rotateY(360deg) rotateZ(-90deg);
  }
}
@keyframes rotate {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotateY(90deg) rotateZ(90deg);
            transform: rotateY(90deg) rotateZ(90deg);
  }
  40% {
    -webkit-transform: rotateY(180deg) rotateZ(-90deg);
            transform: rotateY(180deg) rotateZ(-90deg);
  }
  60% {
    -webkit-transform: rotateY(270deg) rotateZ(90deg);
            transform: rotateY(270deg) rotateZ(90deg);
  }
  80% {
    -webkit-transform: rotateY(360deg) rotateZ(-90deg);
            transform: rotateY(360deg) rotateZ(-90deg);
  }
}

/* @charset "UTF-8";


body {
  color: #2c3e50;
  background: #ecf0f1;
  padding: 0 1em 1em;
} */

input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.row {
  display: -webkit-flex;
  display: flex;
}
.row .col {
  -webkit-flex: 1 1;
          flex: 1 1;
}
.row .col:last-child {
  margin-left: 1em;
}

/* Accordion styles */
.tabs {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}

.tab {
  max-width: 500px;
  color: white;
  overflow: hidden;
}
.tab-label {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 1em;
  background: black;
  border: 1px solid white;
  border-radius: 40px;
  font-weight: bold;
  cursor: pointer;
  /* Icon */
}
.tab-label:hover {
  background: white;
  color: black;
}
.tab-label::after {
  content: "❯";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}
.tab-content {
  max-height: 0;
  padding: 0 1em;
  color: white;
  background: black;
  transition: all 0.35s;
}
.tab-close {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}
.tab-close:hover {
  background: white;
  color: black;
}

input:checked + .tab-label {
  background: white;
  color: black;
}
input:checked + .tab-label::after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 1em;
}

.hero-wrapper {
  /* background: blanchedalmond; */
  width: 100vw;
  /* margin-top: 50px; */
}

.hero {
  max-width: 1440px;
  margin-left: 240px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  /* margin-top: -100px; */
  background: black;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.hero-mobile-content {
  width: 400px;
}
/* .mobile-cube {
  background: url("../../assets/img/herotest1.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 450px;
  height: 500px;
} */

.hero-mobile {
  display: none;
}
@media (max-width: 960px) {
  .hero {
    display: none;
  }
  .hero-mobile {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 80vw;
    /* 
    background: green; */
    margin: auto;
  }
  .hero-wrapper {
    margin-top: 100px;
  }
}

@media (max-width: 1920px) {
  .hero {
    margin-left: 320px;
  }
}

.hero-left,
.hero-right,
.hero-mobile {
  -webkit-animation: 2s ease-out 0s 1 slideInFromLeft;
          animation: 2s ease-out 0s 1 slideInFromLeft;
}
@-webkit-keyframes slideInFromLeft {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes slideInFromLeft {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

/* .hero-right {
  animation: 1s ease-out 0s 1 slideInFromRight;
}
@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
} */

.my-moon {
  width: 700px;
  height: 700px;
  margin-left: 100px;
  margin-top: -150px;
}
@media (max-width: 1400px) {
  .my-moon {
    width: 500px;
    height: 500px;
    margin-left: 100px;
    margin-top: -50px;
  }
}

@media (max-width: 1200px) {
  .hero-mobile {
    display: -webkit-flex;
    display: flex;
    width: 100vw;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .my-moon {
    margin-top: 50px;
  }
}
@media (max-width: 1718px) {
  .hero {
    display: none;
  }
  .hero-mobile {
    display: -webkit-flex;
    display: flex;
    width: 80vw;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    /* padding-top: 30px; */

    margin: auto;
  }
}

@media (max-width: 676px) {
  .mobile-cube {
    display: none;
  }
  .hero-mobile-content {
    width: 320px;
    margin-top: -10px;
  }
}

@media (max-width: 330px) {
  .mobile-cube {
    display: none;
  }
  .hero-mobile-content {
    width: 200px;
    margin-top: -10px;
  }
}

.navbar-wrapper {
  background: black;

  position: fixed;
  /* position: -webkit-sticky; */
  z-index: 100;
  top: 0;
  width: 100vw;

  padding-top: 20px;

  /* width: 100%; */
}

.navbar {
  max-width: 1280px;
  /* min-width: 100%; */
  margin: auto;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  /* background: antiquewhite; */
}

.navbar-right {
  min-width: 640px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  /* background-color: crimson; */
}

.my-link {
  font-size: 18px;
  padding: 12px;
  z-index: 10000;
  color: white;
}
.my-link:hover {
  color: grey;
  text-decoration: none;
  cursor: pointer;
}
.navbar-right > .active {
  border-bottom: 1px solid white;
}

.navbar-button {
  background: white;
  color: black;
  text-decoration: none;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;

  border-radius: 16px;
}

.navbar-button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  color: #fff;
}
.logo {
  /* margin-top: 20px; */
  padding-bottom: 8px;
}
.navbar-menu {
  display: none;
}
.menu-wrapper {
  display: none;
}

@media (max-width: 960px) {
  .navbar-right {
    display: none;
  }
  .navbar-menu {
    display: block;
    margin-left: 20px;
  }
  .menu-wrapper {
    display: -webkit-flex;
    display: flex;
  }
  .menu-list {
    padding-top: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;

    width: 100vw;
    height: 100vh;
    position: relative;
    z-index: 100;
    text-align: center;
  }

  .menu-list > .active {
    color: white;
  }

  .menu-item {
    padding: 10px;
    text-decoration: none;
    color: white;
    cursor: pointer;
  }
  .menu-item:hover {
    color: grey;
    text-decoration: none;
  }
  .menu-connect {
    background: white;
    color: black;
    border-radius: 40px;
  }
  .logo {
    margin-left: 10px;
    min-width: 50px;
  }
  .navbar {
    max-width: 800px;
    margin-left: 10px;
  }
  .navbar-wrapper {
    width: 100vw;
    margin: auto;
    position: fixed;
    top: 0;
    -webkit-backface-visibility: hidden;
  }
}

@media (max-width: 1200px) {
  .navbar {
    max-width: 900px;
  }
}

.navbar {
  -webkit-animation: 1s ease-out 0s 1 slideInFromTop;
          animation: 1s ease-out 0s 1 slideInFromTop;
}
@-webkit-keyframes slideInFromTop {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes slideInFromTop {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

#Group28 {
  -webkit-animation: MoveUpDown 1s linear infinite;
          animation: MoveUpDown 1s linear infinite;
  position: absolute;
  left: 0;
  bottom: 0;
}

@-webkit-keyframes MoveUpDown {
  0%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
  }
}

@keyframes MoveUpDown {
  0%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
  }
}

.founded {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  max-width: 1280px;
  margin: auto;
  margin-top: 200px;
  margin-bottom: 200px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.founded-content > p {
  margin-top: 40px;
  width: 400px;
}
.founded-wrapper {
  /* background: black; */
}
.founded-image > img {
  /* background: url("../../assets//img/cylinder.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 600px;
  height: 900px; */
  width: 500px;
  height: 500px;
}
@media (max-width: 1306px) {
  .founded {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100vw;
  }
  .founded-image > img {
    width: 500px;
    height: 500px;
    margin-bottom: 150px;
  }
}
@media (max-width: 960px) {
  .founded {
    /* display: none; */
    width: 50vw;
    margin: auto;
  }
  .founded-content > p {
    margin-top: 40px;
    width: 50vw;
  }
  .founded-image > img {
    width: 60vw;
    height: 60vh;
    margin-bottom: 100px;
  }
}

.about {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  max-width: 1280px;
  margin: auto;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding-top: 100px;
}
.about-content > p {
  margin-top: 40px;
  width: 400px;
}
.about-wrapper {
  /* background: black; */
}
.about-image > img {
  width: 500px;
  height: 600px;
}

@media (max-width: 1306px) {
  .about {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100vw;
  }
}
@media (max-width: 960px) {
  .about {
    /* display: none; */
    width: 50vw;
    margin: auto;
  }
  .about-content > p {
    margin-top: 40px;
    width: 50vw;
  }
  .about-image > img {
    min-width: 60vw;
    min-height: 50vh;
  }
}

@media (max-width: 600px) {
  .about-image > img {
    width: 40vw;
    height: 30vh;
  }
}

.maincourse {
  height: 100vh;
  margin-top: -100px;
}
.temproary {
  display: none;
}

@media (max-width: 862px) {
  .maincourse-wrapper {
    display: none;
  }
  .maincourse {
    height: auto;
    margin-top: 100px;
  }
  .temproary {
    display: block;
  }
  .maincourse-header {
    display: none;
  }
}

.new-head {
  color: black;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff;
}

header {
}

/* The footer is fixed to the bottom of the page */

footer {
  /* position: fixed;
  bottom: 0; */
  max-width: 1280px;
  margin: auto;
}

@media (max-height: 800px) {
  footer {
    position: static;
  }
  header {
    padding-top: 40px;
  }
}
.footer-contact {
  background: white;
  color: black;
  padding: 8px;
  border-radius: 25px;
  box-shadow: -1px 2px 4px 0px rgb(255, 255, 255);
}

.footer-contact:hover {
  text-decoration: none;
  /* -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8); */
  color: grey;
}
.footer-distributed {
  /* background-color: #2c292f; */
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  /* font: bold 16px sans-serif; */
  padding: 50px 50px 60px 50px;
  margin-top: 160px;
  /* margin-right: 100px; */
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
  display: inline-block;
  vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left {
  width: 30%;
}

.footer-distributed h3 {
  color: #ffffff;
  /* font: normal 36px "Cookie", cursive; */
  margin: 0;
}

/* The company logo */

.footer-distributed .footer-left img {
  width: 30%;
}

.footer-distributed h3 span {
  /* color: #e0ac1c; */
}

/* Footer links */

.footer-distributed .footer-links {
  color: #ffffff;
  margin: 20px 0 12px;
}

.footer-distributed .footer-links a {
  display: inline-block;
  line-height: 1.8;
  text-decoration: none;
  color: inherit;
}

.footer-distributed .footer-company-name {
  color: #8f9296;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

/* Footer Center */

.footer-distributed .footer-center {
  width: 35%;
}

.footer-distributed .footer-center i {
  background-color: #33383b;
  color: #ffffff;
  font-size: 25px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope {
  font-size: 17px;
  line-height: 38px;
}

.footer-distributed .footer-center p {
  display: inline-block;
  color: #ffffff;
  vertical-align: middle;
  margin: 0;
}

.footer-distributed .footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.footer-distributed .footer-center p a {
  color: #fff;
  text-decoration: none;
}

/* Footer Right */

.footer-distributed .footer-right {
  width: 30%;
}

.footer-distributed .footer-company-about {
  line-height: 20px;
  color: #92999f;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
}

.footer-distributed .footer-company-about span {
  display: block;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer-distributed .footer-icons {
  margin-top: 25px;
}

.footer-distributed .footer-icons a {
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  /* background-color: #33383b; */
  border-radius: 2px;

  font-size: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 35px;

  margin-right: 3px;
  margin-bottom: 5px;
}

/* Here is the code for Responsive Footer */
/* You can remove below code if you don't want Footer to be responsive */

@media (max-width: 880px) {
  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right {
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }

  .footer-distributed .footer-center i {
    margin-left: 0;
  }
  .footer-distributed {
    margin-top: 200px;
  }
}

.changer {
  background: white;
  color: black;
}
.secret-wrapper {
  margin-top: 300px;
}
.secret-header {
}
.secret-header-bottom {
  margin-bottom: 100px;
  color: black;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff;
}
@media (max-width: 960px) {
  .secret-wrapper {
    margin-top: 100px;
  }
}

.newhero-wrapper {
  /* width: 1440px; */
  height: 100vh;
  margin: auto;
}
.newhero {
  max-width: 1440px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;

  padding-top: 190px;
  margin: auto;
  /* background: green; */
}
.newhero-header {
  font-size: 96px;
}

.new-blocks {
  display: -webkit-flex;
  display: flex;
  width: 1000px;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 100px;

  /* background: green; */
}

.new-blocks > img {
  width: 120px;
  height: 120px;
  -webkit-animation: animate 20s linear infinite;
          animation: animate 20s linear infinite;
  -webkit-perspective: 800px;
          perspective: 800px;
}
@-webkit-keyframes animate {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
  100% {
    -webkit-transform: rotateX(360deg);
            transform: rotateX(360deg);
  }
}
@keyframes animate {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
  100% {
    -webkit-transform: rotateX(360deg);
            transform: rotateX(360deg);
  }
}

