.about {
  display: flex;
  justify-content: space-between;
  max-width: 1280px;
  margin: auto;
  flex-wrap: wrap;
  padding-top: 100px;
}
.about-content > p {
  margin-top: 40px;
  width: 400px;
}
.about-wrapper {
  /* background: black; */
}
.about-image > img {
  width: 500px;
  height: 600px;
}

@media (max-width: 1306px) {
  .about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }
}
@media (max-width: 960px) {
  .about {
    /* display: none; */
    width: 50vw;
    margin: auto;
  }
  .about-content > p {
    margin-top: 40px;
    width: 50vw;
  }
  .about-image > img {
    min-width: 60vw;
    min-height: 50vh;
  }
}

@media (max-width: 600px) {
  .about-image > img {
    width: 40vw;
    height: 30vh;
  }
}
