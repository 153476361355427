.maincourse {
  height: 100vh;
  margin-top: -100px;
}
.temproary {
  display: none;
}

@media (max-width: 862px) {
  .maincourse-wrapper {
    display: none;
  }
  .maincourse {
    height: auto;
    margin-top: 100px;
  }
  .temproary {
    display: block;
  }
  .maincourse-header {
    display: none;
  }
}

.new-head {
  color: black;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff;
}
