.changer {
  background: white;
  color: black;
}
.secret-wrapper {
  margin-top: 300px;
}
.secret-header {
}
.secret-header-bottom {
  margin-bottom: 100px;
  color: black;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff;
}
@media (max-width: 960px) {
  .secret-wrapper {
    margin-top: 100px;
  }
}
