.hero-wrapper {
  /* background: blanchedalmond; */
  width: 100vw;
  /* margin-top: 50px; */
}

.hero {
  max-width: 1440px;
  margin-left: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: -100px; */
  background: black;
  flex-wrap: wrap;
}
.hero-mobile-content {
  width: 400px;
}
/* .mobile-cube {
  background: url("../../assets/img/herotest1.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 450px;
  height: 500px;
} */

.hero-mobile {
  display: none;
}
@media (max-width: 960px) {
  .hero {
    display: none;
  }
  .hero-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 80vw;
    /* 
    background: green; */
    margin: auto;
  }
  .hero-wrapper {
    margin-top: 100px;
  }
}

@media (max-width: 1920px) {
  .hero {
    margin-left: 320px;
  }
}

.hero-left,
.hero-right,
.hero-mobile {
  animation: 2s ease-out 0s 1 slideInFromLeft;
}
@keyframes slideInFromLeft {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

/* .hero-right {
  animation: 1s ease-out 0s 1 slideInFromRight;
}
@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
} */

.my-moon {
  width: 700px;
  height: 700px;
  margin-left: 100px;
  margin-top: -150px;
}
@media (max-width: 1400px) {
  .my-moon {
    width: 500px;
    height: 500px;
    margin-left: 100px;
    margin-top: -50px;
  }
}

@media (max-width: 1200px) {
  .hero-mobile {
    display: flex;
    width: 100vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .my-moon {
    margin-top: 50px;
  }
}
@media (max-width: 1718px) {
  .hero {
    display: none;
  }
  .hero-mobile {
    display: flex;
    width: 80vw;
    justify-content: space-evenly;
    /* padding-top: 30px; */

    margin: auto;
  }
}

@media (max-width: 676px) {
  .mobile-cube {
    display: none;
  }
  .hero-mobile-content {
    width: 320px;
    margin-top: -10px;
  }
}

@media (max-width: 330px) {
  .mobile-cube {
    display: none;
  }
  .hero-mobile-content {
    width: 200px;
    margin-top: -10px;
  }
}
