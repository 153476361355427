.navbar-wrapper {
  background: black;

  position: fixed;
  /* position: -webkit-sticky; */
  z-index: 100;
  top: 0;
  width: 100vw;

  padding-top: 20px;

  /* width: 100%; */
}

.navbar {
  max-width: 1280px;
  /* min-width: 100%; */
  margin: auto;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  /* background: antiquewhite; */
}

.navbar-right {
  min-width: 640px;
  display: flex;
  justify-content: space-evenly;
  /* background-color: crimson; */
}

.my-link {
  font-size: 18px;
  padding: 12px;
  z-index: 10000;
  color: white;
}
.my-link:hover {
  color: grey;
  text-decoration: none;
  cursor: pointer;
}
.navbar-right > .active {
  border-bottom: 1px solid white;
}

.navbar-button {
  background: white;
  color: black;
  text-decoration: none;
  transition: transform 0.2s;

  border-radius: 16px;
}

.navbar-button:hover {
  transform: scale(1.1);
  color: #fff;
}
.logo {
  /* margin-top: 20px; */
  padding-bottom: 8px;
}
.navbar-menu {
  display: none;
}
.menu-wrapper {
  display: none;
}

@media (max-width: 960px) {
  .navbar-right {
    display: none;
  }
  .navbar-menu {
    display: block;
    margin-left: 20px;
  }
  .menu-wrapper {
    display: flex;
  }
  .menu-list {
    padding-top: 40px;
    display: flex;
    flex-direction: column;

    width: 100vw;
    height: 100vh;
    position: relative;
    z-index: 100;
    text-align: center;
  }

  .menu-list > .active {
    color: white;
  }

  .menu-item {
    padding: 10px;
    text-decoration: none;
    color: white;
    cursor: pointer;
  }
  .menu-item:hover {
    color: grey;
    text-decoration: none;
  }
  .menu-connect {
    background: white;
    color: black;
    border-radius: 40px;
  }
  .logo {
    margin-left: 10px;
    min-width: 50px;
  }
  .navbar {
    max-width: 800px;
    margin-left: 10px;
  }
  .navbar-wrapper {
    width: 100vw;
    margin: auto;
    position: fixed;
    top: 0;
    -webkit-backface-visibility: hidden;
  }
}

@media (max-width: 1200px) {
  .navbar {
    max-width: 900px;
  }
}

.navbar {
  animation: 1s ease-out 0s 1 slideInFromTop;
}
@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

#Group28 {
  animation: MoveUpDown 1s linear infinite;
  position: absolute;
  left: 0;
  bottom: 0;
}

@keyframes MoveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
}
